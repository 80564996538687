body {
  margin: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.App {
  text-align: center;
}


.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.76);
  color: white;
  overflow-x: hidden;
}

.header-container{
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
}

.header-container h4{
  margin-left: 10px;
  font-weight: bolder;
  font-size: 15px;
}


.header-container span{
  margin-left: 10px;
  font-weight: bolder;
  font-size: 15px;
  color: rgb(10, 218, 10);
}

.footer{
  background-color:rgb(27, 27, 27);
  bottom: 0;
  width: 100%;
  padding: 14px 0px;
  position: fixed;
}


.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: rgb(27, 27, 27);
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  from {
      transform: translateX(0%);
  }
  to {
      transform: translateX(-100%);
  }
}

.home-container{
  color: white;
}

.adm-tab-bar-item-active{
  color: rgb(10, 218, 10)!important;
}

.adm-capsule-tabs-tab-active{
  color: rgb(10, 218, 10)!important;
  background-color: black!important;
}

.adm-capsule-tabs-tab{
  background-color: black!important;
}

.team-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(8, 250, 0, 0.26);
  padding: 0 10px;
  border-radius: 10px;
}

.row-container{
  background-color: rgb(31, 30, 30);
}

.team-item-two{
  display: flex;
  align-items: center;
}

.match-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-team{
  display: flex;
  align-items: center;
  padding: 9px;
}

.match-time{
  padding:9px 5px;
  background-color: rgba(8, 250, 0, 0.26);
  width: 80px;
  text-align: center;
  border-radius: 5px;
}


.news-container {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.25);
  border-radius: 12px;
  border: 1px solid rgba(10, 218, 10, 0.411);  
  padding: 10px;  
  filter: drop-shadow(0 30px 10px rgba(0,0,0,0.125));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  text-align: center;
  margin-bottom: 7px;
}

.news_container{
  padding: 9px; 
}

.wrapper {
  width: 100%;
  height: 100%;
  
}

.adm-list-body {
  background-color: black!important;
}

h1{
  font-family: 'Righteous', sans-serif;
  color: rgba(255,255,255,0.98);
  text-transform: uppercase;
  font-size: 2.4rem;
}

p {
  color: #fff;
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-size: 0.8rem;
  line-height: 150%;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-wrapper{
  margin-top: 18px;
}

.collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active, .collapsible:hover {
  background-color: #555;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}

.btn {
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 12px;
  font-size: 0.8rem;  
  letter-spacing: 2px;  
  cursor: pointer;
}

.btn + .btn {
  margin-left: 10px;
}

.outline {
  background: transparent;
  color: rgba(0, 212, 255, 0.9);
  border: 1px solid rgba(0, 212, 255, 0.6);
  transition: all .3s ease;
  
}

.outline:hover{
  transform: scale(1.125);
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.9);
  transition: all .3s ease;  
}

.fill {
  background: rgba(0, 212, 255, 0.9);
  color: rgba(255,255,255,0.95);
  filter: drop-shadow(0);
  font-weight: bold;
  transition: all .3s ease; 
}

.fill:hover{
  transform: scale(1.125);  
  border-color: rgba(255, 255, 255, 0.9);
  filter: drop-shadow(0 10px 5px rgba(0,0,0,0.125));
  transition: all .3s ease;    
}

.news-header{
  text-align: center;
  color: rgb(10, 218, 10);
}

.spinner_container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  color: #fff;
}
  .loader:before,
  .loader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
  }
  .loader:after {
    color: rgb(10, 218, 10);
    transform: rotateY(70deg);
    animation-delay: .4s;
  }

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}
