.collapsible {
    background-color: #242323;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    
    transition: background-color 0.4s ease;
  }
  
  .active, .collapsible:hover {
    background-color: #202020;
  }
  
  .content {
    padding: 0 18px;
    display: none;
    margin-bottom: 10px;
    overflow: hidden;
    background-color: #252424;
  }